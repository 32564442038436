import * as React from 'react';

const THRESHOLD = 200;

const getLimits = (sentences: SentenceI[]) =>
  sentences.map((sentence, i, arr) => {
    if (i === 0) {
      return [0, arr[i + 1].start + THRESHOLD];
    }

    if (i === arr.length - 1) {
      return [sentence.start + THRESHOLD, Infinity];
    }

    return [sentence.start + THRESHOLD, arr[i + 1].start + THRESHOLD];
  });

const isSentenceBeingPlayed = (sentence: SentenceI, currentTime: number) =>
  sentence.start <= currentTime && currentTime < sentence.end;

const defaultState = {
  canPlayThrough: false,
  currentTime: 0,
  duration: 0,
  error: null,
  playing: false,
  seeked: false,
  sentenceBeingPlayed: null,
};

interface State {
  canPlayThrough: boolean;
  currentTime: number;
  duration: number;
  playing: boolean;
  sentenceBeingPlayed: SentenceI | null;
  seeked: boolean;
  error: Error | null;
  sentences: SentenceI[];
  src: string;
}

interface Props {
  src: string;
  sentences: SentenceI[];
  children: (
    {
      canPlayThrough,
      currentTime,
      duration,
      playing,
      sentenceBeingPlayed,
      seeked,
      error,
    }: State & {
      playPause: () => void;
      play: () => void;
      pause: () => void;
      stop: () => void;
      playSentence: (sentence: SentenceI) => void;
      seek: (value: number) => void;
      isSentenceBeingPlayed: (sentence: SentenceI) => boolean;
      playFromStart: () => void;
    }
  ) => React.ReactNode;
}
type Partial<T> = { [P in keyof T]?: T[P] };

const CAN_PLAY_THROUGH = 'Can play through';
const SEEK = 'Seek';
const SET_ERROR = 'Set error';
const AUDIO_ENDED = 'Audio ended';
const AUDIO_PLAY_PAUSE = 'Audio play pause';
const AUDIO_BACKWARD = 'Audio backward';
const AUDIO_FORWARD = 'Audio forward';
const AUDIO_PLAY_SENTENCE = 'Audio play sentence';
const AUDIO_TIME_UPDATE = 'Audio timeupdate';
const STOP_PLAYING_SENTENCE = 'Stop playing sentence';

interface SentenceI {
  start: number;
  end: number;
}

type Action =
  | {
      type: 'Can play through';
      payload: number;
    }
  | {
      type: 'Seek';
      payload: number;
    }
  | {
      type: 'Set error';
      payload: Error;
    }
  | {
      type: 'Audio ended';
    }
  | {
      type: 'Audio play pause';
    }
  | {
      type: 'Audio backward';
    }
  | {
      type: 'Audio forward';
    }
  | {
      type: 'Audio play sentence';
      payload: SentenceI;
    }
  | {
      type: 'Audio timeupdate';
      payload: number;
    }
  | {
      type: 'Stop playing sentence';
    }
  | {
      type: 'Play';
    }
  | {
      type: 'Pause';
    }
  | {
      type: 'Stop';
    }
  | {
      type: 'Play from start';
    };

const reducer = (state: State, action: Action): Partial<State> | null => {
  switch (action.type) {
    case 'Play from start': {
      return {
        currentTime: 0,
        seeked: true,
        playing: true,
        sentenceBeingPlayed: null,
      };
    }
    case 'Play': {
      if (state.playing) {
        return null;
      }
      return {
        playing: true,
        seeked: false,
        sentenceBeingPlayed: null,
      };
    }
    case 'Pause': {
      if (!state.playing) {
        return null;
      }
      return {
        playing: false,
        seeked: false,
        sentenceBeingPlayed: null,
      };
    }
    case 'Stop': {
      return {
        currentTime: 0,
        playing: false,
        seeked: true,
        sentenceBeingPlayed: null,
      };
    }
    case CAN_PLAY_THROUGH: {
      if (state.canPlayThrough) {
        return null;
      }
      return {
        canPlayThrough: true,
        duration: action.payload,
      };
    }
    case SEEK:
      return {
        currentTime: action.payload,
        seeked: true,
        sentenceBeingPlayed: null,
      };
    case SET_ERROR:
      return {
        ...defaultState,
        error: action.payload,
      };
    case AUDIO_ENDED:
      return {
        playing: false,
        sentenceBeingPlayed: null,
      };
    case AUDIO_PLAY_PAUSE:
      return {
        playing: !state.playing,
        seeked: false,
        sentenceBeingPlayed: null,
      };
    case AUDIO_BACKWARD: {
      const { sentences, currentTime } = state;
      if (currentTime === 0) {
        return null;
      }

      let nextCurrentTime;
      if (currentTime < sentences[0].start) {
        nextCurrentTime = 0;
      } else {
        const limits = getLimits(sentences);
        const index = limits.findIndex(
          ([start, end]) => currentTime >= start && currentTime < end
        );

        nextCurrentTime = sentences[index].start;
      }

      return {
        currentTime: nextCurrentTime,
        seeked: true,
      };
    }
    case AUDIO_FORWARD: {
      const { sentences, currentTime, duration } = state;
      let nextCurrentTime;

      if (currentTime < sentences[0].start) {
        nextCurrentTime = sentences[0].start;
      } else {
        const nextSentence = sentences.find((sentence, i) => {
          if (i === 0) {
            return false;
          }
          if (i === sentences.length - 1 && currentTime > sentence.start) {
            return false;
          }
          return (
            currentTime >= sentences[i - 1].start &&
            currentTime < sentence.start
          );
        });
        nextCurrentTime = nextSentence ? nextSentence.start : duration;
      }

      return {
        currentTime: nextCurrentTime,
        seeked: true,
      };
    }
    case AUDIO_PLAY_SENTENCE: {
      if (state.sentenceBeingPlayed === action.payload) {
        return null;
      }
      return {
        currentTime: state.canPlayThrough
          ? action.payload.start
          : state.currentTime,
        playing: true,
        seeked: true,
        sentenceBeingPlayed: action.payload,
      };
    }
    case AUDIO_TIME_UPDATE: {
      if (!state.seeked || state.playing) {
        return {
          currentTime: action.payload,
          seeked: false,
        };
      }
      return null;
    }
    case STOP_PLAYING_SENTENCE:
      return {
        playing: false,
        sentenceBeingPlayed: null,
      };
    default:
      return null;
  }
};

export class AudioPlayer extends React.Component<Props, State> {
  static getDerivedStateFromProps(
    nextProps: Props,
    prevState: State
  ): Partial<State> | null {
    if (nextProps.src !== prevState.src) {
      return {
        ...defaultState,
        src: nextProps.src,
      };
    }

    return null;
  }

  audio?: HTMLAudioElement;
  unsubscribe?: () => void;
  intervalId?: number;

  constructor(props: Props) {
    super(props);
    this.state = {
      ...defaultState,
      sentences: this.props.sentences,
      src: this.props.src,
    };
  }

  componentDidMount() {
    this.initAudio();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.src !== this.state.src) {
      this.initAudio();
    }

    if (this.audio) {
      if (
        prevState.currentTime !== this.state.currentTime &&
        this.state.seeked
      ) {
        this.audio.currentTime = this.state.currentTime / 1000;
      }

      if (
        !prevState.canPlayThrough &&
        this.state.canPlayThrough &&
        this.state.sentenceBeingPlayed
      ) {
        this.audio.currentTime = Math.round(
          this.state.sentenceBeingPlayed.start / 1000
        );
      }
      if (!prevState.playing && this.state.playing) {
        this.audio.play();
      }

      if (prevState.playing && !this.state.playing) {
        this.audio.pause();
      }

      if (prevState.sentenceBeingPlayed !== this.state.sentenceBeingPlayed) {
        this.clearInterval();
        if (this.state.sentenceBeingPlayed) {
          const { end } = this.state.sentenceBeingPlayed;
          const endInSeconds = end / 1000;
          this.intervalId = window.setInterval(() => {
            if (this.audio && this.audio.currentTime >= endInSeconds) {
              this.dispatch(STOP_PLAYING_SENTENCE);
            }
          }, 16);
        }
      }
    }
  }

  clearInterval() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  initAudio() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }

    const audio = new Audio(this.state.src);
    audio.preload = 'none';
    this.audio = audio;

    const events: Array<[string, () => void]> = [
      ['canplaythrough', this.handleCanPlayThrough],
      ['timeupdate', this.handleTimeUpdate],
      ['ended', this.handleEnded],
      ['error', this.handleError],
    ];

    events.forEach(([event, handler]) => {
      audio.addEventListener(event, handler);
    });

    this.unsubscribe = () => {
      events.forEach(([event, handler]) => {
        audio.removeEventListener(event, handler);
      });
    };
  }

  dispatch(type: any, payload?: any) {
    this.setState(prevState => {
      const nextState: any = reducer(prevState, { type, payload });
      return nextState;
    });
  }

  handleCanPlayThrough = () => {
    this.audio &&
      this.dispatch(CAN_PLAY_THROUGH, Math.round(this.audio.duration * 1000));
  };

  handleSeek = (value: number) => {
    this.dispatch(SEEK, value);
  };

  handleTimeUpdate = () => {
    this.audio &&
      this.dispatch(
        AUDIO_TIME_UPDATE,
        Math.max(
          Math.round(this.audio.currentTime * 1000),
          this.state.currentTime
        )
      );
  };

  handleError = () => {
    this.audio && this.dispatch(SET_ERROR, this.audio.error);
  };

  handleEnded = () => {
    this.dispatch(AUDIO_ENDED);
  };

  handlePlayPause = () => {
    this.dispatch(AUDIO_PLAY_PAUSE);
  };

  handleBackward = () => {
    this.dispatch(AUDIO_BACKWARD);
  };

  handleForward = () => {
    this.dispatch(AUDIO_FORWARD);
  };

  playSentence = (sentence: SentenceI) => {
    this.dispatch(AUDIO_PLAY_SENTENCE, sentence);
  };

  handlePlay = () => {
    this.dispatch('Play');
  };

  handlePause = () => {
    this.dispatch('Pause');
  };

  handleStop = () => {
    this.dispatch('Stop');
  };

  hanelPlayFromStart = () => {
    this.dispatch('Play from start');
  };

  isSentenceBeingPlayed = (sentence: SentenceI) => {
    const { currentTime, canPlayThrough } = this.state;
    return canPlayThrough && isSentenceBeingPlayed(sentence, currentTime);
  };

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }

    if (this.audio && !this.audio.paused) {
      this.audio.pause();
    }

    this.clearInterval();
  }

  render() {
    const {
      currentTime,
      playing,
      canPlayThrough,
      sentences,
      duration,
    } = this.state;

    return this.props.children({
      ...this.state,
      isSentenceBeingPlayed: this.isSentenceBeingPlayed,
      playPause: this.handlePlayPause,
      playSentence: this.playSentence,
      pause: this.handlePause,
      seek: this.handleSeek,
      play: this.handlePlay,
      stop: this.handleStop,
      playFromStart: this.hanelPlayFromStart,
    });
  }
}
