import * as React from 'react';
import { Button } from 'semantic-ui-react';
import { AudioButtonUI } from './AudioButtonUI';

interface Props {
  src: string;
}

interface State {
  playing: boolean;
  canPlayThrough: boolean;
  loadedmetadata: boolean;
}

export class AudioButton extends React.Component<Props, State> {
  state = {
    canPlayThrough: false,
    loadedmetadata: false,
    playing: false,
  };
  audio: HTMLAudioElement;

  constructor(props: Props) {
    super(props);

    const audio = new Audio(this.props.src);
    audio.preload = 'metadata';
    this.audio = audio;
  }

  componentDidMount() {
    this.audio.addEventListener('canplaythrough', () => {
      this.setState({
        canPlayThrough: true,
      });
    });

    this.audio.addEventListener('loadedmetadata', () => {
      this.setState({
        loadedmetadata: true,
      });
    });

    this.audio.addEventListener('ended', () => {
      this.setState({
        playing: false,
      });
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const isPlaying = this.state.playing;
    const wasPlaying = prevState.playing;
    if (wasPlaying !== isPlaying) {
      if (isPlaying) {
        this.audio.play();
      } else {
        this.audio.pause();
        this.audio.currentTime = 0;
      }
    }
  }

  play = () => {
    this.setState({
      playing: true,
    });
  };

  stop = () => {
    this.setState({
      playing: false,
    });
  };

  render() {
    const { playing, loadedmetadata, canPlayThrough } = this.state;

    // const loading = playing && !canPlayThrough;

    return (
      <AudioButtonUI
        play={this.play}
        playing={playing}
        canPlayThrough={canPlayThrough}
        stop={this.stop}
      />
    );
  }
}
