import * as React from 'react';
import { BrowserRouter, Link, Route } from 'react-router-dom';
import { Container } from 'semantic-ui-react';

import { LessonsList } from './common/LessonsList';
import { Lesson } from './common/Lesson';

export class App extends React.Component {
  public render() {
    return (
      <BrowserRouter>
        <Container>
          <Route
            path="/"
            exact={true}
            render={() => (
              <React.Fragment>
                <div>
                  <Link to="/en">en</Link>
                </div>
                <div>
                  <Link to="/fr">fr</Link>
                </div>
              </React.Fragment>
            )}
          />
          <Route
            path="/:language"
            render={({
              match: {
                isExact,
                path,
                params: { language },
              },
            }) =>
              isExact ? (
                <LessonsList language={language} />
              ) : (
                <Route
                  path={`${path}/lessons/:lessonId`}
                  render={({
                    match: {
                      params: { lessonId },
                    },
                  }) =>
                    typeof lessonId === 'string' && (
                      <Lesson lessonId={lessonId} language={language} />
                    )
                  }
                />
              )
            }
          />
        </Container>
      </BrowserRouter>
    );
  }
}
