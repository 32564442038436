import * as React from 'react';
import { LessonWarmupType, LanguageType, WarmupType } from '../types';
import { Section, SectionProps } from './Section';
import { LessonDataProvider } from '../content/LessonDataProvider';
import { getAudioUrl } from './getAudioUrl';
import { SentenceWithTranslation } from './SentenceWithTranslation';

export class Warmup extends React.Component<{
  warmup: LessonWarmupType;
  language: LanguageType;
}> {
  render() {
    const { warmup, language } = this.props;

    let getBody: SectionProps['children'] = () => null;

    if (warmup.type === 'i-you' || warmup.type === 'i-you-mummy-daddy') {
      getBody = ({ showTranslation }) =>
        warmup.sentences.map(sentence => (
          <SentenceWithTranslation
            key={sentence.eng}
            sentence={sentence}
            language={language}
            showTranslation={showTranslation}
          />
        ));
    }

    return (
      <LessonDataProvider>
        {data => {
          const { audioName, title } = data.warmups[warmup.type];

          return (
            <Section
              name={`«${title}»`}
              color="blue"
              audio={audioName && getAudioUrl(language, audioName)}
              help={data.warmups[warmup.type].body}
              hasTranslation={
                'sentences' in warmup && 'eng' in warmup.sentences[0]
              }
            >
              {getBody}
            </Section>
          );
        }}
      </LessonDataProvider>
    );
  }
}
