import * as React from 'react';
import { LanguageType } from '../types';
import { AudioButton } from './AudioButton';
import { getAudioUrl } from './getAudioUrl';

export class SentenceWithTranslation extends React.Component<{
  sentence: {
    eng: string;
    rus?: string;
    audioName: string;
  };
  language: LanguageType;
  showTranslation: boolean;
}> {
  render() {
    const {
      sentence: { eng, rus, audioName },
      language,
      showTranslation,
    } = this.props;

    return (
      <React.Fragment key={eng}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'end',
          }}
        >
          <div>
            <AudioButton src={getAudioUrl(language, audioName)} />
          </div>
          <div style={{ margin: 'auto 0' }}>
            <div>{rus}</div>
            {showTranslation && (
              <div style={{ fontStyle: 'italic' }}>{eng}</div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
