import Raven from 'raven-js';

import { LANGUAGES, LanguageType } from '../types';

import { slugify } from 'transliteration';

Raven.config(
  'https://8487af4a77114641b35e7ed1befd60b9@sentry.io/624707'
).install();

const codesStorageKey = 'magic-piano-codes';
const fullCodeRegex = new RegExp(
  `(.+?-.+?)-(${LANGUAGES.join('|')})-(\\d+)-(\\d+)`
);

const codes = {
  'c3lyYXlhLXRhYmxpY2E=': 'en-1-10',
  bmV2eXNva29lLXBpc21v: 'en-11-20',
  'c3RyYWhvdmF5YS1yZXZvbHl1Y2l5YQ==': 'en-21-30',
  ZG9wb2xuaXRlbG5vZS1zZWxv: 'en-31-40',
  'c2hrb2xuYXlhLXBsYW5ldGE=': 'en-41-50',
  'a29tcHl1dGVybmF5YS1zdGVuYQ==': 'en-51-60',
  'bWluZXJhbG55eS10YW5lYw==': 'en-61-70',
  cmF6bm9vYnJhem55eS1rdXN0: 'fr-1-10',
  'cHJpdnljaG55eS1zYXBvZw==': 'fr-11-20',
  cmFib2NoaXktZ29k: 'fr-21-30',
  'dmlydXNuYXlhLWNlbmE=': 'fr-31-40',
  'YXJtZXlza2l5LWl5dW4=': 'fr-41-50',
  'aW5kaXZpZHVhbG55eS12eWhvZA==': 'fr-51-60',
  'ZGVyZXZ5YW5ueXktemF2b2Q=': 'fr-61-70',
};

export const uniq: <T>(arr: Array<T>) => Array<T> = arr =>
  Array.from(new Set(arr));

export const getLessonIdsFromCode = (language: LanguageType, code: string) => {
  const match = code.match(fullCodeRegex);

  const emptyCodeLessons = [] as Array<number>;

  if (!match) {
    Raven.captureException(
      { message: 'неверный формат кода' },
      {
        extra: {
          language,
          code,
        },
      }
    );

    return { codeLessons: emptyCodeLessons, error: 'неверный формат кода' };
  }

  const [, words, codeLanguage, codeFrom, codeTo] = match;

  if (codeLanguage !== language) {
    Raven.captureException(
      { message: 'неверный язык кода' },
      {
        extra: {
          language,
          code,
          codeLanguage,
        },
      }
    );

    return { codeLessons: emptyCodeLessons, error: 'неверный язык кода' };
  }

  if (`${codeLanguage}-${codeFrom}-${codeTo}` !== codes[btoa(slugify(words))]) {
    Raven.captureException(
      { message: 'неверные кодовые слова' },
      {
        extra: {
          language,
          code,
          codeLanguage,
          slugify: slugify(words),
          btoa: btoa(slugify(words)),
          words,
        },
      }
    );

    return { codeLessons: emptyCodeLessons, error: 'неверные кодовые слова' };
  }

  const from = +codeFrom;
  const to = +codeTo;

  if (from > to) {
    Raven.captureException(
      { message: 'некорректные номера уроков' },
      {
        extra: {
          language,
          code,
          codeLanguage,
          btoa: btoa(slugify(words)),
          words,
          from,
          to,
        },
      }
    );

    return {
      codeLessons: emptyCodeLessons,
      error: 'некорректные номера уроков',
    };
  }

  return {
    codeLessons: new Array(to - from + 1)
      .fill(undefined)
      .map((_, id) => from + id),
    error: null,
  };
};

export const getAvailableLessonIds = (language: LanguageType) =>
  uniq(
    getLessonCodes()
      .map(code => getLessonIdsFromCode(language, code))
      .reduce((prev, cur) => prev.concat(cur.codeLessons), [] as Array<number>)
  );

export const getLessonCodes: () => Array<string> = () => {
  const codes = localStorage.getItem(codesStorageKey);
  if (!codes) return [];

  try {
    return JSON.parse(codes);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return [];
  }
};

export const setLessonCodes = (codes: Array<string>) => {
  localStorage.setItem(codesStorageKey, JSON.stringify(codes));
};

export const addLessonCode = (code: string) => {
  localStorage.setItem(
    codesStorageKey,
    JSON.stringify(uniq(getLessonCodes().concat([code])))
  );
};
