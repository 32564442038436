import * as React from 'react';
import { Header } from 'semantic-ui-react';
import { LessonGameType, LanguageType } from '../types';
import { Section, SectionProps } from './Section';
import { LessonDataProvider } from '../content/LessonDataProvider';
import { SentenceWithTranslation } from './SentenceWithTranslation';
import { CardGrid } from '../ui/CardGrid';
import { MPCard } from './MPCard';

export class Game extends React.Component<{
  game: LessonGameType;
  language: LanguageType;
}> {
  render() {
    const { game, language } = this.props;

    let getBody: SectionProps['children'];

    if (game.type === 'reverse-ball') {
      getBody = () => (
        <React.Fragment>
          <CardGrid>
            {game.cardIds.map(cardId => (
              <MPCard key={cardId} cardId={cardId} language={language} />
            ))}
          </CardGrid>
          <div>{game.eng}</div>
          <div>{game.rus}</div>
        </React.Fragment>
      );
    }

    if (game.type === 'mice-bears-humans') {
      getBody = ({ showTranslation }) =>
        game.sentences.map(sentence => (
          <SentenceWithTranslation
            key={sentence.audioName}
            sentence={sentence}
            language={language}
            showTranslation={showTranslation}
          />
        ));
    }

    if (game.type === 'jumps-with-obstacles') {
      getBody = ({ showTranslation }) =>
        game.sentences.map(sentence => (
          <SentenceWithTranslation
            key={sentence.audioName}
            sentence={sentence}
            language={language}
            showTranslation={showTranslation}
          />
        ));
    }

    return (
      <LessonDataProvider>
        {data => (
          <React.Fragment>
            <Header size="medium">Игра</Header>
            <Section
              name={`${data.games[game.type].title}`}
              color="orange"
              // audio={`/audio/${audioName}`}
              help={data.games[game.type].body}
              hasTranslation={'sentences' in game && 'eng' in game.sentences[0]}
            >
              {getBody}
            </Section>
          </React.Fragment>
        )}
      </LessonDataProvider>
    );
  }
}
