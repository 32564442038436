import * as React from 'react';
import { Button } from 'semantic-ui-react';

export const AudioButtonUI = ({
  playing,
  canPlayThrough,
  stop,
  play,
}: {
  playing: boolean;
  canPlayThrough: boolean;
  stop: () => void;
  play: () => void;
}) => (
  <Button
    color={playing ? 'red' : 'teal'}
    circular={true}
    loading={playing && !canPlayThrough}
    icon={playing ? (canPlayThrough ? 'stop' : 'asterisk') : 'play'}
    onClick={playing ? stop : play}
  />
);
