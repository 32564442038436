import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  Header,
  List,
  Input,
  Button,
  InputOnChangeData,
  Form,
  Label,
} from 'semantic-ui-react';

import { LanguageType } from '../types';
import {
  uniq,
  addLessonCode,
  getLessonIdsFromCode,
  getAvailableLessonIds,
} from './LessonCodes';
import { LessonDataProvider } from '../content/LessonDataProvider';

enum codeStatusEnum {
  INPUT,
  ERROR,
}

type State = {
  curCode: string;
  availableLessonIds: Array<number>;
  codeStatus: codeStatusEnum;
  codeError: string | null;
};

export class LessonsList extends React.Component<
  { language: LanguageType },
  State
> {
  state: State = {
    curCode: '',
    availableLessonIds: getAvailableLessonIds(this.props.language),
    codeStatus: codeStatusEnum.INPUT,
    codeError: null,
  };

  submitCode = () => {
    const { error, codeLessons } = getLessonIdsFromCode(
      this.props.language,
      this.state.curCode
    );

    if (codeLessons.length === 0) {
      this.setState({
        codeStatus: codeStatusEnum.ERROR,
        codeError: error,
      });
    } else {
      addLessonCode(this.state.curCode);

      this.setState(({ availableLessonIds }) => ({
        codeStatus: codeStatusEnum.INPUT,
        curCode: '',
        availableLessonIds: uniq(availableLessonIds.concat(codeLessons)),
      }));
    }
  };

  onCodeChange = (event: any, { value }: InputOnChangeData) => {
    this.setState({ curCode: value });
  };

  render() {
    const { language } = this.props;
    const { availableLessonIds, curCode, codeStatus, codeError } = this.state;

    return (
      <React.Fragment>
        <Header size="huge">Уроки</Header>
        <LessonDataProvider>
          {({ lessons }) => (
            <List link={true}>
              {lessons
                .filter(({ id }) => availableLessonIds.includes(id))
                .map(({ id }) => (
                  <List.Item
                    key={id}
                    as={Link}
                    to={`/${language}/lessons/${id}`}
                  >
                    Урок №{id}
                  </List.Item>
                ))}
            </List>
          )}
        </LessonDataProvider>

        <Form>
          <Form.Field>
            <Input
              placeholder="Введите код"
              onChange={this.onCodeChange}
              value={curCode}
            />{' '}
            {codeStatus === codeStatusEnum.ERROR && (
              <Label basic color="red" pointing>
                Неверный код ({codeError})
              </Label>
            )}
          </Form.Field>
          <Button onClick={this.submitCode}>Отправить</Button>
        </Form>
      </React.Fragment>
    );
  }
}
