import * as React from 'react';
import { Header, Message, Grid } from 'semantic-ui-react';
import { LessonSongType, LanguageType } from '../types';
import { CardGrid } from '../ui/CardGrid';
import { MPCard } from './MPCard';
import { Section } from './Section';
import { LessonDataProvider } from '../content/LessonDataProvider';
import { getAudioUrl } from './getAudioUrl';

export class Song extends React.Component<{
  song: LessonSongType;
  language: LanguageType;
}> {
  render() {
    const {
      song: { songId, body },
      language,
    } = this.props;

    return (
      <LessonDataProvider>
        {data => (
          <React.Fragment>
            <Header size="medium">Песенка</Header>
            <Section
              key={songId}
              name={`Песенка ${songId}`}
              color="olive"
              audio={getAudioUrl(language, data.songs[songId].audioName)}
              hasTranslation
              help={
                <React.Fragment>
                  Перед тем, как начать работать над очередной песенкой,
                  необходимо разложить на столе перед детьми все карточки,
                  которые задействованы в тексте песни, в том порядке, в котором
                  они встречаются по ходу песенки. Каждая карточка – клавиша
                  волшебного пианино. Когда начинает играть музыка, с детьми
                  происходит удивительное превращение, они перевоплощаются
                  одновременно и в пианистов, и в рояль. Очень важно нажимать
                  пальчиком на каждую клавишу-карточку в тот момент, когда вы
                  произносите грамматическую формулу, которую эта карточка
                  символизирует.
                </React.Fragment>
              }
            >
              {({ showTranslation }) => (
                <React.Fragment>
                  <CardGrid>
                    {data.songs[songId].cards.map(cardId => (
                      <MPCard
                        key={cardId}
                        cardId={cardId}
                        language={language}
                      />
                    ))}
                  </CardGrid>
                  {body ? <p>{body}</p> : null}
                  <Message>
                    <Grid columns={2} stackable>
                      <Grid.Row>
                        <Grid.Column>
                          {data.songs[songId].sentences.map(({ rus }, i) => (
                            <div key={i}>{rus}</div>
                          ))}
                        </Grid.Column>
                        {showTranslation && (
                          <Grid.Column>
                            {data.songs[songId].sentences.map(({ eng }, i) => (
                              <div key={i}>{eng}</div>
                            ))}
                          </Grid.Column>
                        )}
                      </Grid.Row>
                    </Grid>
                  </Message>
                </React.Fragment>
              )}
            </Section>
          </React.Fragment>
        )}
      </LessonDataProvider>
    );
  }
}
