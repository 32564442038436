import * as React from 'react';
import { MPModal } from '../common/MPModal';

export class CardGrid extends React.Component<{
  children: React.ReactNode[];
}> {
  render() {
    const { children } = this.props;
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {children.map((card, i) => (
          <div
            style={{
              padding: 5,
              width: `${100 / 3}%`,
            }}
            key={i}
          >
            <MPModal header={'Карточка'} trigger={<div>{card}</div>}>
              {card}
            </MPModal>
          </div>
        ))}
      </div>
    );
  }
}
