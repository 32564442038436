import * as React from 'react';
import { Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { Warmup } from '../common/Warmup';
import { Newcard } from '../common/Newcard';
import { Song } from '../common/Song';
import { Game } from '../common/Game';
import {
  LessonType,
  LanguageType,
  PianistTranslatorLessonGameType,
} from '../types';
import { getAvailableLessonIds } from './LessonCodes';
import { LessonDataProvider } from '../content/LessonDataProvider';
import { PianistTranslator } from './PianistTranslator';
import { getAudioUrl } from './getAudioUrl';

export class Lesson extends React.Component<{
  lessonId: string;
  language: LanguageType;
}> {
  render() {
    const { language, lessonId } = this.props;

    const backLink = (
      <Link color="teal" to={`/${language}`}>
        Вернуться к списку уроков
      </Link>
    );
    return (
      <LessonDataProvider>
        {({ lessons }) => {
          const lesson: LessonType | void = lessons.find(
            ({ id }) => id === +lessonId
          );

          if (!lesson || !getAvailableLessonIds(language).includes(lesson.id)) {
            return (
              <React.Fragment>
                {backLink}
                <Header size="huge" textAlign="center">
                  Урок {lesson ? 'недоступен' : 'не найден'}
                </Header>
              </React.Fragment>
            );
          }

          return (
            <div
              style={{
                paddingTop: 15,
              }}
            >
              {backLink}
              <Header
                size="huge"
                textAlign="center"
                color="teal"
                dividing={true}
              >
                Урок {lesson.id}
              </Header>
              {lesson.warmups.length !== 0 && (
                <Header size="medium">Разминка</Header>
              )}

              {lesson.warmups.map((warmup, i) => (
                <Warmup key={i} warmup={warmup} language={language} />
              ))}

              {lesson.exercises.map((exercise, i) => {
                switch (exercise.type) {
                  case 'new-card':
                    return (
                      <Newcard
                        key={i}
                        cardId={exercise.cardId}
                        body={exercise.body}
                        language={language}
                      />
                    );

                  case 'song':
                    return <Song key={i} song={exercise} language={language} />;

                  case 'pianist-translator':
                    return (
                      <PianistTranslator
                        key={i}
                        game={exercise}
                        language={language}
                      />
                    );

                  case 'rehersal':
                    const prevLesson = lessons.find(
                      ({ id }) => id === +lessonId - 1
                    ) as LessonType;

                    const prevPianistTranslator = prevLesson.exercises.find(
                      ({ type }) => type === 'pianist-translator'
                    ) as PianistTranslatorLessonGameType;

                    return (
                      <PianistTranslator
                        key={i}
                        game={prevPianistTranslator}
                        language={language}
                        rehersal
                      />
                    );

                  default:
                    return <Game key={i} game={exercise} language={language} />;
                }
              })}

              {lesson.homeworkAudioName && (
                <React.Fragment>
                  <Header size="medium">Домашнее задание</Header>
                  <audio
                    src={getAudioUrl(language, lesson.homeworkAudioName)}
                    style={{
                      width: '100%',
                    }}
                    controls={true}
                  />
                </React.Fragment>
              )}
            </div>
          );
        }}
      </LessonDataProvider>
    );
  }
}
