import * as React from 'react';
import { Icon, Modal } from 'semantic-ui-react';

export const MPModal = ({
  trigger,
  children,
  header,
}: {
  trigger: React.ReactNode;
  children: React.ReactNode;
  header: React.ReactNode;
}) => (
  <Modal
    trigger={trigger}
    style={{
      position: 'relative',
    }}
    closeIcon={
      <Icon
        style={{
          position: 'absolute',
          right: '.5rem',
          top: '1rem',
        }}
        name="close"
      />
    }
  >
    <Modal.Header>{header}</Modal.Header>
    <Modal.Content>{children}</Modal.Content>
  </Modal>
);
