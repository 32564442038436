import * as React from 'react';
import { Route } from 'react-router';
import { DataProviderComponentType } from '../types';

const LessonDataEnLazy = React.lazy(() =>
  import(/* webpackChunkName: "LessonDataEn" */ './LessonDataEn')
);
const LessonDataFrLazy = React.lazy(() =>
  import(/* webpackChunkName: "LessonDataFr" */ './LessonDataFr')
);

const langsToComponents = {
  en: LessonDataEnLazy,
  fr: LessonDataFrLazy,
};

export const LessonDataProvider: DataProviderComponentType = ({ children }) => (
  <Route
    path="/:lang"
    render={({
      match: {
        params: { lang },
      },
    }) => {
      const Wrapper: typeof LessonDataEnLazy | void = langsToComponents[lang];

      if (!Wrapper) {
        return null;
      }

      return (
        <React.Suspense fallback={null}>
          <Wrapper children={children} />
        </React.Suspense>
      );
    }}
  />
);
