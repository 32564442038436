import * as React from 'react';

import { PianistTranslatorLessonGameType, LanguageType } from '../types';
import { LessonDataProvider } from '../content/LessonDataProvider';
import { AudioPlayer } from './AudioPlayer';
import { getAudioUrl } from './getAudioUrl';
import { Section } from './Section';
import { AudioButtonUI } from './AudioButtonUI';
import { MPCardIcon } from './MPCard';
import { Header } from 'semantic-ui-react';

export class PianistTranslator extends React.Component<{
  game: PianistTranslatorLessonGameType;
  language: LanguageType;
  rehersal?: boolean;
}> {
  render() {
    const { game, language, rehersal } = this.props;

    return (
      <LessonDataProvider>
        {data => (
          <React.Fragment>
            <Header size="medium">Игра</Header>
            <AudioPlayer
              sentences={game.sentences!}
              src={getAudioUrl(language, game.audioName)}
            >
              {({
                playSentence,
                playFromStart,
                stop,
                playing,
                canPlayThrough,
                isSentenceBeingPlayed,
              }) => (
                <Section
                  name={
                    rehersal
                      ? data.games.rehersal.title
                      : data.games[game.type].title
                  }
                  color="orange"
                  extraButton={
                    <AudioButtonUI
                      stop={stop}
                      play={playFromStart}
                      playing={playing}
                      canPlayThrough={canPlayThrough}
                    />
                  }
                  help={
                    rehersal
                      ? data.games.rehersal.body
                      : data.games[game.type].body
                  }
                  hasTranslation
                >
                  {({ showTranslation }) => (
                    <React.Fragment>
                      <div
                        style={{
                          textAlign: 'justify',
                          marginBottom: 15,
                        }}
                      >
                        {game.sentences!.map((sentence, i) => {
                          const playing = isSentenceBeingPlayed(sentence);
                          return (
                            <span
                              key={i}
                              style={{
                                marginRight: 3,
                              }}
                            >
                              <span
                                style={{
                                  backgroundColor: playing
                                    ? '#00b5ad'
                                    : '#e8e8e8',
                                  borderRadius: 6,
                                  color: playing ? 'white' : 'black',
                                  lineHeight: '30px',
                                  padding: '3px 6px',
                                }}
                                onClick={() => {
                                  playSentence(sentence);
                                }}
                              >
                                {sentence.rus}
                              </span>
                              {sentence.cardIds[0] && (
                                <MPCardIcon
                                  language={language}
                                  cardId={sentence.cardIds[0]}
                                />
                              )}
                            </span>
                          );
                        })}
                      </div>
                      {showTranslation && (
                        <p style={{ fontStyle: 'italic' }}>
                          {game.sentences!.map(({ eng }) => eng).join(' ')}
                        </p>
                      )}
                    </React.Fragment>
                  )}
                </Section>
              )}
            </AudioPlayer>
          </React.Fragment>
        )}
      </LessonDataProvider>
    );
  }
}
