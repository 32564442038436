import * as React from 'react';
import { Button, Card, Header } from 'semantic-ui-react';
import { $ElementProps, $PropertyType } from '../types';
import { AudioButton } from './AudioButton';
import { MPModal } from './MPModal';

export type SectionProps = {
  name?: string;
  children: (
    { showTranslation }: { showTranslation: boolean }
  ) => React.ReactNode;
  audio?: string;
  help?: React.ReactNode;
  color?: $PropertyType<$ElementProps<typeof Card>, 'color'>;
  extraButton?: React.ReactNode;
  hasTranslation?: boolean;
};

export class Section extends React.Component<
  SectionProps,
  { showTranslation: boolean }
> {
  state = {
    showTranslation: false,
  };

  toggleshowTranslation = () => {
    this.setState(({ showTranslation }) => ({
      showTranslation: !showTranslation,
    }));
  };

  render() {
    const {
      name,
      audio,
      children,
      help,
      color,
      extraButton,
      hasTranslation,
    } = this.props;

    const { showTranslation } = this.state;

    return (
      <Card fluid={true} color={color}>
        <Card.Content>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <div style={{ flex: 1 }}>
              <Header size="small">{name}</Header>
            </div>
            <div>
              {extraButton}
              {audio && <AudioButton src={audio} />}
              {hasTranslation && (
                <Button
                  onClick={this.toggleshowTranslation}
                  circular={true}
                  icon="translate"
                />
              )}
              {!!help && (
                <MPModal
                  header={name}
                  trigger={<Button circular={true} icon="help" />}
                >
                  <div
                    style={{
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {help}
                  </div>
                </MPModal>
              )}
            </div>
          </div>
          {!!children && (
            <div
              style={{
                marginTop: 15,
                whiteSpace: 'pre-wrap',
              }}
            >
              {children({ showTranslation })}
            </div>
          )}
        </Card.Content>
      </Card>
    );
  }
}
