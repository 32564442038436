import * as React from 'react';
import { Header } from 'semantic-ui-react';
import { LessonNewCardType, LanguageType } from '../types';
import { MPCard } from './MPCard';
import { Section } from './Section';

export class Newcard extends React.Component<{
  cardId: LessonNewCardType['cardId'];
  body: LessonNewCardType['body'];
  language: LanguageType;
}> {
  render() {
    const { cardId, body, language } = this.props;

    return (
      <React.Fragment>
        <Header size="medium">Новая карточка</Header>
        <Section color="olive">
          {() => (
            <React.Fragment>
              <MPCard cardId={cardId} language={language} />
              <div dangerouslySetInnerHTML={{ __html: body }} />
            </React.Fragment>
          )}
        </Section>
      </React.Fragment>
    );
  }
}
