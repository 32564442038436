import * as React from 'react';

export type $PropertyType<T extends object, K extends keyof T> = T[K];

export type $ElementProps<T> = T extends React.ComponentType<infer Props>
  ? Props extends object
    ? Props
    : never
  : never;

export type CardIdType = number;

export type PianistTranslatorLessonGameType = {
  type: 'pianist-translator';
  body: string;
  sentences: Array<{
    eng: string;
    rus: string;
    cardIds: Array<number>;
    start: number;
    end: number;
  }>;
  audioName: string;
};

export type LessonWarmupType =
  | {
      type:
        | 'yes-no-1'
        | 'yes-no-2'
        | 'yes-no-3'
        | 'yes-no-4'
        | 'yes-no-5'
        | 'yes-no-6'
        | 'clocks-1'
        | 'clocks-2'
        | 'clocks-3'
        | 'clocks-4'
        | 'where-are-you-1'
        | 'where-are-you-2'
        | 'up-down-1'
        | 'up-down-2'
        | 'up-down-3'
        | 'up-down-4'
        | 'hi-bye'
        | 'come-here-go-away'
        | 'its-mine'
        | 'hands-legs-head';
      audioName: string;
    }
  | {
      type: 'i-you' | 'i-you-mummy-daddy';
      sentences: Array<{
        eng: string;
        rus?: string;
        audioName: string;
      }>;
    };

export type LessonGameType =
  | {
      type:
        | 'treasure-map'
        | 'queen-crown'
        | 'cop-hat'
        | 'rehersal'
        | 'give-here-take-it'
        | 'bag'
        | 'quiz'
        | 'change'
        | 'fairy-tale'
        | 'small-stories'
        | 'hide-and-seek'
        | 'commander-and-dont-wanter';
    }
  | {
      type:
        | 'close-eyes'
        | 'mushrooms'
        | 'what-do-you-have-i'
        | 'what-do-you-have-ii'
        | 'what-are-you-doing'
        | 'leave-it-for-tomorrow'
        | 'small-stories'
        | 'lift'
        | 'cards';

      body?: string;
    }
  | {
      type: 'mice-bears-humans';
      sentences: Array<{ eng: string; rus?: string; audioName: string }>;
      cardId?: CardIdType;
    }
  | {
      type: 'jumps-with-obstacles';
      sentences: Array<{
        eng: string;
        rus: string;
        audioName: string;
        cardIds: Array<CardIdType>;
      }>;
    }
  | {
      type: 'reverse-ball';
      eng: string;
      rus: string;
      cardIds: Array<CardIdType>;
    }
  | PianistTranslatorLessonGameType;

export type LessonNewCardType = {
  type: 'new-card';
  cardId: CardIdType;
  body: string;
};

export type LessonSongType = {
  type: 'song';
  songId: string;
  body?: string;
};

export type LessonExerciseType =
  | LessonNewCardType
  | LessonGameType
  | LessonSongType;

export type LessonType = {
  id: number;
  homeworkAudioName?: string;
  warmups: Array<LessonWarmupType>;
  exercises: Array<LessonExerciseType>;
};

export type SongType = {
  cards: Array<number>;
  audioName: string;
  body: string;
  sentences: Array<{
    eng: string;
    rus: string;
    cardIds: Array<CardIdType>;
  }>;
};

export type GameType = {
  title: string;
  body: string | React.ReactNode;
};

export type WarmupType = {
  title: string;
  body: string | React.ReactNode;
  audioName?: string;
};

const stringLiteralArray: <T extends string>(a: T[]) => T[] = a => a;

export const LANGUAGES = stringLiteralArray(['en', 'fr']);

export type LanguageType = typeof LANGUAGES[number];

export type DataProviderComponentType = React.ComponentType<{
  children: (
    props: {
      lessons: Array<LessonType>;
      games: {
        [id: string]: GameType;
      };
      warmups: {
        [id: string]: WarmupType;
      };
      songs: {
        [id: string]: SongType;
      };
    }
  ) => React.ReactNode;
}>;
