import * as React from 'react';
import { Image, Label } from 'semantic-ui-react';

import { MPModal } from '../common/MPModal';
import { $ElementProps, $PropertyType } from '../types';

import { LanguageType } from '../types';

import cardEn1 from 'magic-piano-shared/cards/en/card1.png';
import cardEn2 from 'magic-piano-shared/cards/en/card2.png';
import cardEn3 from 'magic-piano-shared/cards/en/card3.png';
import cardEn4 from 'magic-piano-shared/cards/en/card4.png';
import cardEn5 from 'magic-piano-shared/cards/en/card5.png';
import cardEn6 from 'magic-piano-shared/cards/en/card6.png';
import cardEn7 from 'magic-piano-shared/cards/en/card7.png';
import cardEn8 from 'magic-piano-shared/cards/en/card8.png';
import cardEn9 from 'magic-piano-shared/cards/en/card9.png';
import cardEn10 from 'magic-piano-shared/cards/en/card10.png';
import cardEn11 from 'magic-piano-shared/cards/en/card11.png';
import cardEn12 from 'magic-piano-shared/cards/en/card12.png';
import cardEn13 from 'magic-piano-shared/cards/en/card13.png';
import cardEn14 from 'magic-piano-shared/cards/en/card14.png';
import cardEn15 from 'magic-piano-shared/cards/en/card15.png';
import cardEn16 from 'magic-piano-shared/cards/en/card16.png';
import cardEn17 from 'magic-piano-shared/cards/en/card17.png';
import cardEn18 from 'magic-piano-shared/cards/en/card18.png';
import cardEn19 from 'magic-piano-shared/cards/en/card19.png';

import cardFr1 from 'magic-piano-shared/cards/fr/card1.jpg';
import cardFr2 from 'magic-piano-shared/cards/fr/card2.jpg';
import cardFr3 from 'magic-piano-shared/cards/fr/card3.jpg';
import cardFr4 from 'magic-piano-shared/cards/fr/card4.jpg';
import cardFr5 from 'magic-piano-shared/cards/fr/card5.jpg';
import cardFr6 from 'magic-piano-shared/cards/fr/card6.jpg';
import cardFr7 from 'magic-piano-shared/cards/fr/card7.jpg';
import cardFr8 from 'magic-piano-shared/cards/fr/card8.png';
import cardFr9 from 'magic-piano-shared/cards/fr/card9.png';
import cardFr10 from 'magic-piano-shared/cards/fr/card10.png';
import cardFr11 from 'magic-piano-shared/cards/fr/card11.jpg';
import cardFr12 from 'magic-piano-shared/cards/fr/card12.jpg';
import cardFr13 from 'magic-piano-shared/cards/fr/card13.jpg';
import cardFr14 from 'magic-piano-shared/cards/fr/card14.jpg';
import cardFr15 from 'magic-piano-shared/cards/fr/card15.jpg';
import cardFr16 from 'magic-piano-shared/cards/fr/card16.jpg';
import cardFr17 from 'magic-piano-shared/cards/fr/card17.jpg';
import cardFr18 from 'magic-piano-shared/cards/fr/card18.jpg';
import cardFr19 from 'magic-piano-shared/cards/fr/card19.jpg';

const cards = {
  1: { en: cardEn1, fr: cardFr1 },
  2: { en: cardEn2, fr: cardFr2 },
  3: { en: cardEn3, fr: cardFr3 },
  4: { en: cardEn4, fr: cardFr4 },
  5: { en: cardEn5, fr: cardFr5 },
  6: { en: cardEn6, fr: cardFr6 },
  7: { en: cardEn7, fr: cardFr7 },
  8: { en: cardEn8, fr: cardFr8 },
  9: { en: cardEn9, fr: cardFr9 },
  10: { en: cardEn10, fr: cardFr10 },
  11: { en: cardEn11, fr: cardFr11 },
  12: { en: cardEn12, fr: cardFr12 },
  13: { en: cardEn13, fr: cardFr13 },
  14: { en: cardEn14, fr: cardFr14 },
  15: { en: cardEn15, fr: cardFr15 },
  16: { en: cardEn16, fr: cardFr16 },
  17: { en: cardEn17, fr: cardFr17 },
  18: { en: cardEn18, fr: cardFr18 },
  19: { en: cardEn19, fr: cardFr19 },
};

interface Props {
  cardId: number;
  size?: $PropertyType<$ElementProps<typeof Image>, 'size'>;
  language: LanguageType;
}

export const MPCard = ({ cardId, size, language }: Props) => {
  const card = cards[cardId] && cards[cardId][language];

  return card ? (
    <Image size={size} src={card} style={{ width: '100%', maxWidth: 300 }} />
  ) : (
    <div>Картинка для карточки {cardId} не найдена.</div>
  );
};

export const MPCardIcon = ({ cardId, language }: Props) => (
  <MPModal
    header={`Карточка ${cardId}`}
    trigger={
      <Label size="small" color="orange">
        {cardId}
      </Label>
    }
  >
    <MPCard cardId={cardId} language={language} />
  </MPModal>
);

export const getMPCardIconByLanguage = (language: LanguageType) => ({
  cardId,
}: Pick<Props, 'cardId'>) => MPCardIcon({ cardId, language });
